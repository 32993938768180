import React, { useState, useEffect } from 'react';
import { Nav, Button, Navbar} from 'react-bootstrap';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Logo from "../images/Logo-Pranapnea.svg"
import * as navbarStyle from "../styles/navbarStyle.css"

const NavBar = () => {

  const [navBackground, setNavBackground] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollWind = window.scrollY > 50
      const windLarge = window.innerWidth > 768
      const windSmall = window.innerWidth < 768

      if ( (scrollWind && windLarge) && (!windSmall) ) {
        setNavBackground(true)
      }
      else if(windSmall)
      {
        setNavBackground(true)
      }
      else {
        setNavBackground(false)
      }
    }
    document.addEventListener('scroll', handleScroll)
    window.addEventListener("resize", handleScroll, false);

    // Call this right away so state gets updated with initial window size (No need for the default State function)
    handleScroll();

    return () => {
      document.removeEventListener('scroll', handleScroll)
      window.removeEventListener("resize", handleScroll, false);
    }
  }, [])

  return (
    <div className="navDiv" style={{
     ...navbarStyle,
     backgroundColor: navBackground ? '#2b4462' : 'transparent',
     paddingTop: navBackground ? "0px" : "15px",
    }}>
      <Navbar expand="md lg" variant="dark">
        <Navbar.Brand onClick={() => scrollTo('#landing')}><img className="logo" src={Logo} alt='logo'></img></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link><Button onClick={() => scrollTo('#yoga')}>Yoga</Button></Nav.Link>
            <Nav.Link><Button onClick={() => scrollTo('#freediving')}>Freediving</Button></Nav.Link>
            <Nav.Link><Button onClick={() => scrollTo('#massage')}>Massages</Button></Nav.Link>
            <Nav.Link><Button onClick={() => scrollTo('#event')}>Events</Button></Nav.Link>
            <Nav.Link><Button onClick={() => scrollTo('#home')}>About</Button></Nav.Link>
            <Nav.Link><Button onClick={() => scrollTo('#staff')}>Team</Button></Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <ul className="nav navbar-nav navbar-right me-5">
        <li>
          <Nav.Link><Button className="custom-btn" style={{backgroundColor : "#FFE600"}} onClick={() => scrollTo('#contact')}><strong style={{color: "#4D4500"}}>Contact Us !</strong></Button></Nav.Link>
        </li>
        </ul>
      </Navbar>
    </div>
  );
}

export default NavBar;
