import React from "react"
import {Button, Card, Carousel} from "react-bootstrap"
import scrollTo from 'gatsby-plugin-smoothscroll';
import { StaticImage } from "gatsby-plugin-image";

export default function Events(props) {

const unretained = "../images/unretained.jpg"
const psWorkshop = "../images/psWorkshop.jpeg"
const flyer = "../images/toolsforlife/flyer_TFL.jpg"
const n2 = "../images/flyers/n2.jpg"
const n3 = "../images/flyers/n3.jpg"
const yoga_schedule = "../images/flyers/yoga_schedule_22.jpg"
const transformative_movement = "../images/flyers/transformative-movement.jpg"

  return (
      <section>
        <h1>Events</h1>
        <Card className="shadow bg-transparent border-0">
              <Card.Body><h3 className="text-light">Incoming events</h3>
              <StaticImage
                src={transformative_movement}
                alt="transformative_movement"
                placeholder="blurred"
                layout="constrained"
                height={800}
                quality={100}
              />
              <Card.Text className="text-light mt-2">YeoTown - Madeira</Card.Text>
              </Card.Body>
              <Button onClick={() => scrollTo('#contact')}>Contact Us !</Button>
          </Card>
        <br/>
        <h2>Passed events</h2>
        <Carousel indicators={false}>
  <Carousel.Item >
    <Card className="shadow bg-transparent border-0">
              <Card.Body><h3 className="text-light">Nurture Your Soul</h3>
              <StaticImage
                src={yoga_schedule}
                alt="Nurture Your Soul"
                placeholder="blurred"
                layout="constrained"
                height={800}
                quality={100}
              />
              <Card.Text className="text-light mt-2">Quinta Das Vinhas - Madeira</Card.Text>
              </Card.Body>
              <Button onClick={() => scrollTo('#contact')}>Contact Us !</Button>
          </Card>
                           </Carousel.Item>
          <Carousel.Item >
          <Card className="shadow bg-transparent border-0">
              <Card.Body><h3 className="text-light">Surrender and discover</h3>
              <StaticImage
                src={n3}
                alt="Nurture Your Soul"
                placeholder="blurred"
                layout="constrained"
                height={800}
                quality={100}
              />
              <Card.Text className="text-light mt-2">Quinta Das Vinhas - Madeira</Card.Text>
              </Card.Body>
              <Button onClick={() => scrollTo('#contact')}>Contact Us !</Button>
          </Card>

          </Carousel.Item>
    <Carousel.Item >
          <Card className="shadow bg-transparent border-0">
              <Card.Body><h3 className="text-light">Taking time for you</h3>
              <StaticImage
                src={n2}
                alt="Nurture Your Soul"
                placeholder="blurred"
                layout="constrained"
                height={800}
                quality={100}
              />
              <Card.Text className="text-light mt-2">Quinta Das Vinhas - Madeira</Card.Text>
              </Card.Body>
              <Button onClick={() => scrollTo('#contact')}>Contact Us !</Button>
          </Card>

          </Carousel.Item>
    <Carousel.Item >
          <Card className="shadow bg-transparent border-0">
              <Card.Body><h3 className="text-light">Nurture Your Soul</h3>
              <StaticImage
                src={flyer}
                alt="Nurture Your Soul"
                placeholder="blurred"
                layout="constrained"
                height={800}
                quality={100}
              />
              <Card.Subtitle className="text-light mt-2">Contact-us or visit our dedicated page <a href="https://www.pranapnea.com/toolsforlife">Here</a></Card.Subtitle>
              <Card.Text className="text-light mt-2">From <b>21.10.2022</b> to <b>23.10.2022</b> Quinta Das Vinhas - Madeira</Card.Text>
              </Card.Body>
              <Button onClick={() => scrollTo('#contact')}>Contact Us !</Button>
          </Card>

          </Carousel.Item>
          <Carousel.Item >
<Card className="shadow bg-transparent border-0">
              <Card.Body><h3 className="text-light">Yoga Collaboration Retreat In Porto Santo Island</h3>
              <StaticImage
                src={psWorkshop}
                alt="Yoga Workshop with Sabine Molenaar"
                placeholder="blurred"
                layout="constrained"
                quality={100}
                height={800}
              />
              <Card.Subtitle className="text-light mt-2">Sabi provides yoga classes during the retreat organised by <a href="mailto:lbarrett90@yahoo.com" className="text-light link">@Lindsay Barrett</a></Card.Subtitle>
              <Card.Text className="text-light mt-2">From <b>13.05.2021</b> to <b>16.05.2021</b> @ Porto Santo - Island</Card.Text>
              </Card.Body>
              <Button onClick={() => scrollTo('#contact')}>Contact Us !</Button>
          </Card>
    </Carousel.Item>
          <Carousel.Item >
  <Card className="shadow bg-transparent border-0">
                <Card.Body><h3 className="text-light">Underwater project : <i>"Un-retained"</i></h3>
                <StaticImage
                  src={unretained}
                  alt="Dance Performance - Installation Art - Underwater"
                  placeholder="blurred"
                  layout="constrained"
                  quality={100}
                  height={800}
                />
                <Card.Subtitle className="text-light mt-2">Underwater dance project by<a href="http://sandmandance.com/projects/un-retained" className="text-light link">@sandmandance</a></Card.Subtitle>
                <Card.Text className="text-light mt-2">From <b>09.11.2019</b> to <b>16.11.2019</b> @ GLOW International Light Art Festival - Eindhoven (NL)</Card.Text>
                </Card.Body>
                <Button onClick={() => scrollTo('#contact')}>Contact Us !</Button>
          </Card>
           </Carousel.Item>
          </Carousel>
        </section>
  )
}
